// General
.story__social-icon,
.story__clap-icon {
    display: block;
    flex-shrink: 0;

    .icon {
        max-width: 30px;
        max-height: 30px;
        margin-top: 50%;
        transform: translateY(-50%);
        fill: $color-blue;
        transition: transform 0.45s ease-in-out;
    }
}

// Clap indicator (show only in sidebar)
.story-list__clap {
    display: none;
}

.story-detail__sidebar {
    .icon {
        fill: #999;
        margin-right: 6px;
    }

    .story-list__clap {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 34px;
        margin-bottom: 12px;
        max-width: 56px;

        span {
            position: relative;
            top: 2px;
            line-height: 30px;
            color: $color-lightest-readable-grey;
        }
    }

    .story__clap-icon {
        height: 30px;
    }

    .large-text & .story-list__clap,
    .med-large-text & .story-list__clap {
        height: auto;

        span {
            line-height: 1.2;
        }
    }
}

.story-list__cta-clap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

// All actions in detail view page bottom
.story-detail__actions {
    margin-top: 48px;
    border-bottom: 3px solid $color-blue-light;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 720px) {
        border-top: 3px solid $color-blue-light;
        padding-top: 48px;
    }

    .story__social-icon,
    .story__clap-icon {
        display: block;
        width: 67px;
        height: 68px;
        border-radius: 50%;
        text-align: center;
        border: 2px $color-blue solid;
    }

    .story__social-icon {
        margin-left: 12px;

        & + .social-icon {
            margin-left: 8px;
        }

        &.social-twitter {
            border-color: #1DA1F2;
            svg {
                fill: #1DA1F2;
            }
        }
    }

    .story__clap-icon {
        border-color: $color-green;
        svg {
            fill: $color-green;
        }
    }

    .clap-icon-holder {
        padding-right: 16px;
        position: relative;
    }

    a:hover .icon {
        transform: translateY(-50%) scale(1.2);
    }
}

.story-detail__clap,
.story-detail__share {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    p {
        display: block;
        padding: 0;
        flex-shrink: 1;
        font-weight: bold;
        line-height: 1.35;
    }
}

.story-detail__share {
    padding-bottom: 48px;
}

.story-detail__clap {
    margin-right: 16px;
    margin-bottom: 48px;

    .clap-count {
        font-weight: normal;
        text-align: center;
        position: absolute;
        bottom: -4px;
        transform: translateY(100%);
        font-size: 0.935em;
        width: 100%;
        text-align: left;
    }
}

.story-detail__next {
    border-bottom: 3px $color-blue-light solid;
    padding-bottom: 48px;

    .inner {
        @media (min-width: 720px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .story-list__text {
        padding: 0;
        padding-right: 1em;

        h3 {
            color: $color-text;
            font-family: $base-font;
            font-size: 1.2em;
            margin-bottom: 6px;
        }
    }

    .button {
        padding-top: 9px;
        padding-bottom: 7px;
    }
}
