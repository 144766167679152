.page-footer {
    padding: 3% 0;
    text-align: center;
    color: $color-text;

    img {
        width: 160px;
        margin-bottom: 8px;
    }

    a {
        display: block;
        padding: 4px 0
    }

    ul {
        @extend %reset-list;
        font-size: 1em;
    }

    li + li {
        margin-top: 4px;
    }
}
