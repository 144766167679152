//-------------------------------- Hero cta
.story-list__hero {
    .button {
        flex-shrink: 0;
        position: relative;
        font-size: 2em;
        margin-top: 0.5em;
    }

    .inner-container {
        @media (min-width: 720px) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    @media (min-width: 720px) and (max-width: 1023px) {
        .med-large-text &,
        .large-text & {
            .inner-container {
                display: block;
            }

            .button {
                top: 0;
                margin-top: 0.5em;
            }

            .story-list__hero-text {
                width: 100%;
            }
        }
    }
}

#innerbody:not(.large-text):not(.med-large-text) .story-list__hero-text {
    @media (min-width: 720px) {
        width: 66.66%;
        padding-right: 1em;
    }
}

.story-list__access-link {
    @extend %focus-visible;

    &.button {
        top: -1.5em;
    }
}

.story-list__links {
    position: relative;
}

//-------------------------------- List and cards
.story-list__holder {
    padding-top: 16px;
    transition: opacity 0.45s ease-in-out;

    @media (min-width: 720px) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: -6px;
        width: calc(100% + 12px);
    }

    &.no-transition {
        transition-duration: 0s;
    }

    &.faded-out {
        opacity: 0;
    }
}

.story-list__card {
    position: relative;
    padding: 12px 0;

    @media (min-width: 720px) {
        width: 33.33%;

        &, &:first-child, &:last-child {
            padding: 12px 6px;
        }
    }

    @media (min-width: 720px) and (max-width: 1023px) {
        .med-large-text &,
        .large-text & {
            width: 50%;
        }
    }

    @media (max-width: 920px) {
        .large-text & {
            width: 100%;
        }
    }

    .story-detail__sidebar & {
        @media (min-width: 1200px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    #innerbody:not(.large-text) .story-detail__sidebar & {
        @media (min-width: 1050px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    #innerbody:not(.med-large-text):not(.large-text) .story-detail__sidebar & {
        @media (min-width: 920px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.story-list__card-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f4f4f4;
    position: relative;

    .story-detail__sidebar & {
        background-color: #fff;
        border-bottom: 4px $color-blue-light solid;
        padding-bottom: 20px;
        display: block;
    }
}

.story-list__cta {
    color: #fff;
    display: block;
    text-align: center;
    font-size: 1.3em;
    background-color: $color-blue;
    border-color: $color-blue;
    width: 100%;

    .area-2 & {
        background-color: $color-red;
        border-color: $color-red;
    }

    .area-3 & {
        background-color: $color-green;
        border-color: $color-green;
    }

    &.button {
        padding-right: 20px;

        &:after {
            border-color: #fff;
        }
    }

    .story-detail__sidebar & {
        font-size: 1em;
        padding-top: 9px;
        padding-bottom: 8px;
        flex-grow: 1;
        margin-bottom: 12px;
        max-width: calc(100% - 80px);

        &:after {
            display: none;
        }
    }

    .large-text .story-detail__sidebar & {
        font-size: 0.9em;
    }
}

.story-list__image-holder {
    height: 206px;
    overflow: hidden;
    border-bottom: 6px $color-blue solid;

    .story-detail__sidebar & {
        height: 106px;
        border-top: 6px $color-blue solid;
        border-bottom: none;
    }

    .area-2 & {
        border-color: $color-red;
    }

    .area-3 & {
        border-color: $color-green;
    }
}

.story-list__image {
    height: 206px;
    background-color: #333;
    background-size: cover;
    background-position: center;
    transition: transform 0.6s ease-in-out;
}

.story-list__text {
    padding: 16px;
    padding-bottom: 0;

    .story-detail__sidebar & {
        padding-left: 0;
        padding-right: 0;
    }

    h3:last-child {
        margin-bottom: 0.66em;
    }
}

.story-list__date {
    display: block;
    margin-top: auto;
    padding-left: 16px;
    padding-right: 16px;

    .story-detail__sidebar & {
        display: none;
    }
}

.story-list__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &:hover + .story-list__image-holder .story-list__image {
        transform: scale(1.05);
    }
}
