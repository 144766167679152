// Non google-font fonts
@font-face {
    font-family: 'Verlag Condensed';
    src: url('../fonts/VerlagCondensed-Black.woff2') format('woff2'),
        url('../fonts/VerlagCondensed-Black.woff') format('woff'),
        url('../fonts/VerlagCondensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

// Base sizes
$base-font-size: 1rem !default;
$base-line-height: 1.5 !default;
$base-font: 'Open Sans';
$header-font: 'Verlag Condensed';

// Colors
$color-text: #3c4d59;
$color-blue-light: #d8e8f8;
$color-green: #73c27b;
$color-blue: #5569aa;
$color-red: #c14952;
$color-line: #e2e2e2;
$color-lightest-readable-grey: #595959;

// Size
$width-gap: 1.5rem;
$width-line: 0.125rem;
$width-container: 1400px;
$width-radius: 0.125rem;

// Style
$style-button: none;

// Icon
$icon-width: 1.5em;
$icon-height: $icon-width;


// Headlines

///     FONT SIZE  |  LINE H  |  MARGIN T  |  MARGIN B
$headline-scale: (
    h1: (2.2em,      1.2,       0,           0.3em),
    h2: (2em,        1.2,       0.9em,       0.45em),
    h3: (1.5em,      1.5,       1.33em,      0.66em),
    h4: (1.25em,     1.1,       1.2em,       0.8em),
    h5: (1.1em,      1.1,       1.125em,     0.9em),
    h6: (1em,        1,         1.5em,       0.5em)
) !default;
