.message {
    background-color: $color-blue;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #fff;

    &.success {
        background-color: $color-green;
    }

    p {
        padding: 1em;
        transition: opacity 0.45s ease-in-out;
    }

    &.faded-out p {
        opacity: 0;
        transition-duration: 0s;
    }
}
