img {
    width: 100%;
    height: auto;
    vertical-align: top;
}

figure {
    margin: 0;
}

figcaption {
    display: block;
    vertical-align: top;
    padding: $width-gap / 3;
    color: #fff;
    font-size: 0.875rem;
}

.video__holder,
.audio__holder {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;

    > div,
    video
    audio {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.audio__holder {
    padding-top: 30px;

    .video-js .vjs-control-bar {
        display: flex;
    }

    .video-js .vjs-big-play-button {
        display: none;
    }
}

.video__holder {
    .video-js {
        .vjs-big-play-button {
            height: 3em;
            margin-top: -1.5em;
            border-radius: 50%;
        }

        .vjs-icon-placeholder {
            line-height: 3em;
        }
    }
}
