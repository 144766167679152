.story-detail__top {
    .inner-container {
        padding-top: 0.65em;
        padding-bottom: 0.75em;

        > * {
            margin: 0.25em 1em 0.25em 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .button {
        justify-content: center;

        @media (max-width: 720px) {
            width: 100%;
        }
    }

    p {
        line-height: 1.35;
    }
}

.story-detail__section {
    // partially determine by text size
    margin-top: calc(16px + 1.5em);

    h2 {
        font-family: $base-font;
        color: $color-green;
        font-size: 0.875em;
    }

    h3 {
        margin-bottom: 0;
        color: $color-green;
    }

    p:last-child {
        padding-bottom: 0;
    }
}

.story-detail__sidebar {
    h2,
    h3 {
        font-family: $base-font;
        font-size: 1em;
        font-weight: bold;
    }

    @media (max-width: 919px) {
        margin-top: 32px;
    }

    .med-large-text & {
        @media (max-width: 1049px) {
            margin-top: 32px;
        }
    }

    .large-text & {
        @media (max-width: 1199px) {
            margin-top: 32px;
        }
    }
}

.story-detail__sidebar-holder {
    @media (min-width: 720px) and (max-width: 919px) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: -6px;
        width: calc(100% + 12px);
        justify-content: flex-start;
    }

    .med-large-text & {
        @media (min-width: 720px) and (max-width: 1049px) {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            margin-left: -6px;
            width: calc(100% + 12px);
            justify-content: flex-start;
        }
    }

    .large-text & {
        @media (min-width: 720px) and (max-width: 1199px) {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            margin-left: -6px;
            width: calc(100% + 12px);
            justify-content: flex-start;
        }
    }
}

.story-detail__sidebar-share {
    background-color: $color-blue-light;
    padding: 24px 16px;
    position: relative;

    h3 {
        font-family: $header-font;
        font-size: 1.8em;
        margin-bottom: 0;
        line-height: 1.2;
    }

    .button {
        justify-content: center;
    }

    .story-list__access-link {
        bottom: 24px;
        right: 16px;
        left: 16px;
        top: auto;
    }
}

.story-detail__resource {

    p {
        padding-bottom: 12px;
        font-weight: bold;
        line-height: 1.35;
    }

    #innerbody:not(.med-large-text):not(.large-text) & {
        @media (min-width: 720px) {
            display: flex;
            align-content: center;

            p {
                padding-bottom: 0;
            }

            .button {
                flex-shrink: 0;
                margin-left: 16px;
            }
        }
    }
}
