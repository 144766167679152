@import 'comments-form';
@import 'messages';

form {
    position: relative;
}

.filters {
    position: relative;
    z-index: 2;

    .filter__options {
        @media (min-width: 720px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    input[type="submit"] {
        @extend %focus-visible;
        margin-top: 6px;
        min-width: 200px;
    }

    .control-label {
        display: none;
    }

    p {
        padding-bottom: 4px;;
        line-height: 1.2;

        @media (min-width: 720px) {
            padding-bottom: 8px;
        }
    }
}

.large-text {
    .filters .filter__options {
        @media (max-width: 1023px) {
            display: block;
        }
    }
}

.med-large-text {
    .filters .filter__options {
        @media (max-width: 900px) {
            display: block;
        }
    }
}

.filter__order-by,
.filter__filter-by {
    padding-top: 16px;
    padding-bottom: 16px;

    .radio {
        @extend .button;
        cursor: pointer;
        position: relative;

        &.checked {
            color: #fff;
            background-color: $color-text;
            border-color: $color-text;
        }

        &.focus:after {
            display: block;
        }

        &:after {
            content: '';
            @extend %replicate-webkit-outline;
            position: absolute;
            top: -6px;
            left: -6px;
            right: -6px;
            bottom: -6px;
            display: none;
        }

        input[type="radio"] {
            position: absolute;
            left: -99999px;
        }
    }
}

.filter__filter-by {
    @media (min-width: 720px) {
        margin-right: 24px;
    }

    .controls {
        display: flex;
        flex-wrap: wrap;
    }

    .radio {
        margin-top: 6px;

        &:not(:last-child) {
            margin-right: 6px;
        }

        &:nth-child(2) {
            color: $color-blue;

            &.checked {
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }

        &:nth-child(3) {
            color: $color-red;

            &.checked {
                background-color: $color-red;
                border-color: $color-red;
            }
        }

        &:nth-child(4) {
            color: $color-green;

            &.checked {
                background-color: $color-green;
                border-color: $color-green;
            }
        }

        &.checked {
            color: #fff;
        }
    }
}

.filter__order-by {
    position: relative;

    .dropdown-toggle {
        z-index: 2;
        min-width: 200px;
        margin-top: 6px;
    }

    > .control-group {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3px;
        opacity: 0;
        transform: translateY(50%);
        transition: all 0.3s ease-in-out;

        &.filter__slide-in {
            transform: translateY(100%);
            opacity: 1;
        }
    }

    .radio {
        width: 100%;
    }
}
