.hidden {
    display: none;
}

.inverted {
    // background: $color-inverted-background;
    // color: $color-inverted-text;
}

%reset-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

%replicate-webkit-outline {
    box-shadow: 0 0 10px #9ecaed;
    border: 3px #9ecaed solid !important;
    z-index: 1;
    border-radius: 3px;
}
