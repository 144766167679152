.expand-content {
    padding-top: 1em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
    border-bottom: 3px solid $color-line;
    line-height: 1.6;

    .exp-content-inner {
        padding-bottom: 1em;
    }
}

.expand-button {
    &:first-child {
        margin-top: 0.833em;
        margin-left: auto;
    }

    &[aria-expanded="true"] + .expand-content {
        height: auto;
        max-height: 400px;
        overflow: auto;
    }
}
