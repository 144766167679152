.anim-404 {
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: 520px) {
        display: none;
    }
}

.page-404 {
    min-height: 400px;

    @media (min-width: 720px) {
        display: flex;
        justify-content: flex-start;
    }

    h1 {
        text-transform: uppercase;
    }

    .text-404 {
        padding-top: 2em;
    }
}
