body {
    font-family: $base-font, sans-serif;
    color: $color-text;
}

// From righttoparticipate.org - copying styles
@media (max-width: 720px) {
    p,
    ol,
    ul,
    a {
        font-size: 0.9em;
    }
}


//// HEADLINES

// loop through heading size variable and assign sizes
@each $heading, $font-properties in $headline-scale {
  #{$heading}, .#{$heading} {
    font-size: nth($font-properties, 1);
    line-height: nth($font-properties, 2);
    margin-top: nth($font-properties, 3) * 1;
    margin-bottom: nth($font-properties, 4) * 1;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: $header-font;
}

.med-large-text {
    h3, p {
        line-height: 1.35;
    }
}

.large-text h3 {
    line-height: 1.25;
}


//// COMMON
a {
    color: inherit;
    font-weight: bold;

    &:hover {
        color: $color-green;
    }
}

p {
    line-height: 1.6;
    padding: 0.2em 0 1.2em 0;
    margin: 0;

    &.no-pad-bottom {
        padding-bottom: 0;
    }
}

hr {
    border: none;
    height: $width-gap * 0.125;
    background: $color-text;
}

.dark-text {
    color: $color-text;
}

.light-text {
    color: #fff;
}

.text-smaller {
    font-size: 0.875em;
}
