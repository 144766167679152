.story-detail__comments {
    .control-group {
        margin-top: 24px;
    }

    #div_id_check_spam_field {
        display: none;
    }

    input[type=text],
    textarea {
        width: 100%;
        border: 2px $color-line solid;
        box-sizing: border-box;
        padding: 0.5em;
    }

    label {
        display: block;
        font-size: 0.875em;
        padding-bottom: 6px;
        color: $color-lightest-readable-grey;

        &.hidden {
            display: none;
        }
    }

    input[type=submit] {
        margin-left: auto;
        margin-top: 12px;

        & + p {
            margin-top: 0.5em;
        }
    }
}
