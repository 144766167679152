.content-top-bar {
    color: #fff;
    border-top: 1px $color-line solid;
    background-color: darken($color-text, 5%);

    p {
        padding-bottom: 0;
    }

    .inner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &.area-2 {
        background-color: $color-red;
        border-color: $color-red;
    }

    &.area-3 {
        background-color: $color-green;
        border-color: $color-green;
    }
}

.content-top__info {
    font-family: $header-font;
    letter-spacing: 1px;
    font-size: 1.2em;
    padding-top: 0;
    text-transform: capitalize;
}
