.story-detail__comments-holder {
    &:last-child {
        margin: 48px 0;
    }
}

.story-detail__comment {
    border: 2px $color-line solid;
    padding: 24px;
    padding-top: 26px;

    &:first-child,
    &:last-child {
        margin: 16px 0 12px 0;
    }

    .comment-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 16px;
        line-height: 1.35;
    }

    .comment-name {
        font-weight: bold;
        text-transform: capitalize;
    }

    .comment-date {
        color: $color-lightest-readable-grey;
        font-size: 0.875em;
    }

    .separator:after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #999;
        margin: 0 0.4em;
    }
}

.story-detail__comment-separator {
    border-top: 3px $color-blue-light solid;
    margin: 16px 0 32px 0;
}
