// Top bar of options
.topnav {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: $color-green;
    color: #fff;
    padding: 1% 0;
    font-size: 1rem;
    line-height: 1.35;

    @media (max-width: 520px) {
        display: block;
        text-align: center;
    }

    > div {
        display: flex;
        padding-left: 16px;
        padding-right: 16px;

        @media (max-width: 520px) {
            justify-content: center;
        }
    }

    ul {
        @extend %reset-list;
        margin-left: 0.25rem;
    }

    li,
    p {
        display: inline;
        line-height: 1.5;
        padding: 0;

        @media (max-width: 520px) {
            line-height: 1.3;
        }
    }

    a {
        font-weight: bold;

        &:hover {
            color: $color-text;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.topnav__font-resizer {
    margin-left: auto;

    a {
        padding: 0 2px;
    }
}

// Header main image area
.header__img {
    background-image: url('../img/rtp-header-new.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    height: 322px;

    @media screen and (max-width: 720px) {
        height: 200px;
    }
}

.header__rtp-logo,
.header__dis-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.header__rtp-logo {
    width: 274px;
    height: 107px;
    top: 46px;

    @media screen and (max-width: 720px) {
        width: 180px;
        top: 27px;
    }
}

.header__dis-logo {
    width: 156px;
    height: 80px;
    top: 156px;

    @media screen and (max-width: 720px) {
        display: none;
    }
}
