.nav__primary {
    background-color: $color-text;
    color: #fff;
    padding: 1.3% 0 0 0;
    padding-bottom: calc(2.3% - 8px);

    @media (max-width: 520px) {
        padding: 0.3% 0;
    }

    a {
        font-size: 1.8em;
        text-decoration: none;
        font-family: $header-font;
        text-transform: uppercase;

        @media (max-width: 720px) {
            font-size: 1.4em;
        }

        @media (max-width: 520px) {
            font-size: 1.2em;
        }
    }

    ul {
        @extend %reset-list;
        text-align: center;
    }

    li {
        padding: 1px 20px;

        &:last-child {
            border: none;
        }

        @media (max-width: 720px) {
            padding: 0 15px;
        }

        @media (min-width: 521px) {
            line-height: 1;
            display: inline-block;
            border-right: 1px solid $color-line;
            margin-bottom: 8px;
        }

        @media (max-width: 520px) {
            border-bottom: 1px solid $color-line;
            padding: 10px 0;
        }
    }
}
