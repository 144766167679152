%button__arrow {
    content: '';
    position: relative;
    right: -0.2em;
    top: -0.1em;
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 3px $color-text solid;
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
    transition: transform 0.45s ease-in-out;
    flex-shrink: 0;

    .large-text & {
        top: -0.2em;
    }
}

.button,
.btn,
button,
input[type=button],
input[type="submit"] {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    background-color: #fff;
    color: $color-text;
    border: 3px solid currentcolor;
    text-transform: uppercase;
    padding: 15px 20px 12px 20px;
    font-size: 1.2em;
    text-decoration: none;
    font-family: $header-font;
    line-height: 1;
    text-align: left;
    cursor: pointer;

    .large-text & {
        font-size: 1.1em;
    }

    &.arrow-right,
    &.arrow-dropdown {
        padding: 15px 30px 12px 20px;
        justify-content: center;

        &:after {
            @extend %button__arrow;
        }

        &.btn-inverse:after {
            border-color: #fff;
        }
    }

    &.arrow-left {
        padding: 15px 20px 12px 30px;
        text-align: center;

        &:before {
            @extend %button__arrow;
            right: auto;
            left: -0.2em;
            transform: rotate(-135deg);
        }

        &.btn-inverse:before {
            border-color: #fff;
        }
    }

    &.arrow-dropdown {

        &:after {
            transform: rotate(135deg);
        }

        &[aria-expanded=true]:after {
            transform: translateY(3px) rotate(-45deg);
        }
    }

    &.btn-inverse {
        background-color: $color-text;
        border-color: $color-text;
        color: #fff;
    }

    &.btn-green,
    &.area-3,
    &.area-2,
    &.area-1 {
        &:after {
            border-color: #fff;
        }
    }

    &.btn-green,
    &.area-3 {
        background-color: $color-green;
        border-color: $color-green;
        color: #fff;

        .area-3 & {
            background-color: $color-text;
            border-color: $color-text;
        }
    }

    &.area-1 {
        background-color: $color-blue;
        border-color: $color-blue;
        color: #fff;
    }

    &.area-2 {
        background-color: $color-red;
        border-color: $color-red;
        color: #fff;
    }

    &.off {
        opacity: 0;
        height: 1px;
    }
}
