.pagination {
    font-family: $header-font;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;

    @media (min-width: 721px) {
        .arrow-right {
            order: 3;
        }
    }
}

.pagination__numbers {

    .span-current {
        color: $color-green;
    }

    span,
    a {
        font-size: 1em;
        padding: 0.35em;
        text-decoration: none;
    }

    @media (max-width: 720px) {
        text-align: center;
        width: 100%;
        margin-right: 0;
        margin-top: 1em;
        border-top: 3px solid $color-line;
        padding-top: 0.65em;
    }
}

.large-text {
    @media (max-width: 1023px) {
        .pagination__numbers {
            text-align: center;
            width: 100%;
            margin-right: 0;
            margin-top: 1em;
        }

        .pagination .arrow-right {
            order: 0;
        }
    }
}

.large-text,
.med-large-text {
    @media (max-width: 520px) {
        .pagination span,
        .pagination a {
            width: 100%;
            text-align: center;
        }

        .pagination a + a {
            margin-top: 0.25em;
        }
    }
}
