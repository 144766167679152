body {
    background-color: $color-blue-light;
    transition: opacity 0.3s ease-in-out;

    &.reload-fade {
        opacity: 0.35;
    }
}

main {
    background: #fff;
}

.desktop-break {
    #innerbody:not(.large-text) & {
        @media (min-width: 720px) {
            display: block;
        }
    }
}

.mobile-break {
    @media (max-width: 719px) {
        display: block;
    }
}

.to-top {
    @extend %focus-visible;
}

.inner-container {
    padding: 50px 5%;

    @media (min-width: 1200px) {
        padding: 50px 10%;

        .large-text & {
            padding: 50px 6%;
        }

        .med-large-text & {
            padding: 50px 8%;
        }
    }
}

.container {
    width: 100%;
    max-width: $width-container;
    margin: auto;
    padding: 0;
}

.row {

    @media all and (min-width: 920px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    @media all and (max-width: 919px) {
        div[class*=column-] + div[class*=column-] {
            margin-top: 3rem;
        }
    }
}

*[class*=column-] {
    width: 100%;
}

@media all and (min-width: 920px) {
    .column-3 { width: calc((3/12*100%) - 1.5rem) }
    .column-9 { width: calc((9/12*100%) - 1.5rem) }
}

@media all and (min-width: 1200px) {
    .column-3 { width: calc((3/12*100%) - 2.5rem) }
    .column-9 { width: calc((9/12*100%) - 2.5rem) }
}

.med-large-text {
    @media (max-width: 1049px) {
        .column-3 { width: 100%; }
        .column-9 { width: 100%; }
    }
}

.large-text {
    @media (max-width: 1199px) {
        .column-3 { width: 100%; }
        .column-9 { width: 100%; }
    }
}

main > .container:first-of-type {
    margin-top: 1.5rem;
}
