/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

@font-face {
  font-family: 'Verlag Condensed';
  src: url("../fonts/VerlagCondensed-Black.woff2") format("woff2"), url("../fonts/VerlagCondensed-Black.woff") format("woff"), url("../fonts/VerlagCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

body {
  font-family: "Open Sans", sans-serif;
  color: #3c4d59; }

@media (max-width: 720px) {
  p,
  ol,
  ul,
  a {
    font-size: 0.9em; } }

h1, .h1 {
  font-size: 2.2em;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.3em; }

h2, .h2 {
  font-size: 2em;
  line-height: 1.2;
  margin-top: 0.9em;
  margin-bottom: 0.45em; }

h3, .h3 {
  font-size: 1.5em;
  line-height: 1.5;
  margin-top: 1.33em;
  margin-bottom: 0.66em; }

h4, .h4 {
  font-size: 1.25em;
  line-height: 1.1;
  margin-top: 1.2em;
  margin-bottom: 0.8em; }

h5, .h5 {
  font-size: 1.1em;
  line-height: 1.1;
  margin-top: 1.125em;
  margin-bottom: 0.9em; }

h6, .h6 {
  font-size: 1em;
  line-height: 1;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Verlag Condensed"; }

.med-large-text h3, .med-large-text p {
  line-height: 1.35; }

.large-text h3 {
  line-height: 1.25; }

a {
  color: inherit;
  font-weight: bold; }
  a:hover {
    color: #73c27b; }

p {
  line-height: 1.6;
  padding: 0.2em 0 1.2em 0;
  margin: 0; }
  p.no-pad-bottom {
    padding-bottom: 0; }

hr {
  border: none;
  height: 0.1875rem;
  background: #3c4d59; }

.dark-text {
  color: #3c4d59; }

.light-text {
  color: #fff; }

.text-smaller {
  font-size: 0.875em; }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.to-top, .filters input[type="submit"], .story-list__access-link {
  position: absolute !important;
  z-index: 2; }
  .to-top:not(:focus), .filters input:not(:focus)[type="submit"], .story-list__access-link:not(:focus) {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    opacity: 0; }

.hidden {
  display: none; }

.topnav ul, .page-footer ul, .nav__primary ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.filter__order-by .radio:after,
.filter__filter-by .radio:after {
  box-shadow: 0 0 10px #9ecaed;
  border: 3px #9ecaed solid !important;
  z-index: 1;
  border-radius: 3px; }

.topnav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: #73c27b;
  color: #fff;
  padding: 1% 0;
  font-size: 1rem;
  line-height: 1.35; }
  @media (max-width: 520px) {
    .topnav {
      display: block;
      text-align: center; } }
  .topnav > div {
    display: flex;
    padding-left: 16px;
    padding-right: 16px; }
    @media (max-width: 520px) {
      .topnav > div {
        justify-content: center; } }
  .topnav ul {
    margin-left: 0.25rem; }
  .topnav li,
  .topnav p {
    display: inline;
    line-height: 1.5;
    padding: 0; }
    @media (max-width: 520px) {
      .topnav li,
      .topnav p {
        line-height: 1.3; } }
  .topnav a {
    font-weight: bold; }
    .topnav a:hover {
      color: #3c4d59; }
  .topnav p {
    margin-bottom: 0; }

.topnav__font-resizer {
  margin-left: auto; }
  .topnav__font-resizer a {
    padding: 0 2px; }

.header__img {
  background-image: url("../img/rtp-header-new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  height: 322px; }
  @media screen and (max-width: 720px) {
    .header__img {
      height: 200px; } }

.header__rtp-logo,
.header__dis-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.header__rtp-logo {
  width: 274px;
  height: 107px;
  top: 46px; }
  @media screen and (max-width: 720px) {
    .header__rtp-logo {
      width: 180px;
      top: 27px; } }

.header__dis-logo {
  width: 156px;
  height: 80px;
  top: 156px; }
  @media screen and (max-width: 720px) {
    .header__dis-logo {
      display: none; } }

body {
  background-color: #d8e8f8;
  transition: opacity 0.3s ease-in-out; }
  body.reload-fade {
    opacity: 0.35; }

main {
  background: #fff; }

@media (min-width: 720px) {
  #innerbody:not(.large-text) .desktop-break {
    display: block; } }

@media (max-width: 719px) {
  .mobile-break {
    display: block; } }

.inner-container {
  padding: 50px 5%; }
  @media (min-width: 1200px) {
    .inner-container {
      padding: 50px 10%; }
      .large-text .inner-container {
        padding: 50px 6%; }
      .med-large-text .inner-container {
        padding: 50px 8%; } }

.container {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0; }

@media all and (min-width: 920px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; } }

@media all and (max-width: 919px) {
  .row div[class*=column-] + div[class*=column-] {
    margin-top: 3rem; } }

*[class*=column-] {
  width: 100%; }

@media all and (min-width: 920px) {
  .column-3 {
    width: calc((3/12*100%) - 1.5rem); }
  .column-9 {
    width: calc((9/12*100%) - 1.5rem); } }

@media all and (min-width: 1200px) {
  .column-3 {
    width: calc((3/12*100%) - 2.5rem); }
  .column-9 {
    width: calc((9/12*100%) - 2.5rem); } }

@media (max-width: 1049px) {
  .med-large-text .column-3 {
    width: 100%; }
  .med-large-text .column-9 {
    width: 100%; } }

@media (max-width: 1199px) {
  .large-text .column-3 {
    width: 100%; }
  .large-text .column-9 {
    width: 100%; } }

main > .container:first-of-type {
  margin-top: 1.5rem; }

.page-footer {
  padding: 3% 0;
  text-align: center;
  color: #3c4d59; }
  .page-footer img {
    width: 160px;
    margin-bottom: 8px; }
  .page-footer a {
    display: block;
    padding: 4px 0; }
  .page-footer ul {
    font-size: 1em; }
  .page-footer li + li {
    margin-top: 4px; }

.nav__primary {
  background-color: #3c4d59;
  color: #fff;
  padding: 1.3% 0 0 0;
  padding-bottom: calc(2.3% - 8px); }
  @media (max-width: 520px) {
    .nav__primary {
      padding: 0.3% 0; } }
  .nav__primary a {
    font-size: 1.8em;
    text-decoration: none;
    font-family: "Verlag Condensed";
    text-transform: uppercase; }
    @media (max-width: 720px) {
      .nav__primary a {
        font-size: 1.4em; } }
    @media (max-width: 520px) {
      .nav__primary a {
        font-size: 1.2em; } }
  .nav__primary ul {
    text-align: center; }
  .nav__primary li {
    padding: 1px 20px; }
    .nav__primary li:last-child {
      border: none; }
    @media (max-width: 720px) {
      .nav__primary li {
        padding: 0 15px; } }
    @media (min-width: 521px) {
      .nav__primary li {
        line-height: 1;
        display: inline-block;
        border-right: 1px solid #e2e2e2;
        margin-bottom: 8px; } }
    @media (max-width: 520px) {
      .nav__primary li {
        border-bottom: 1px solid #e2e2e2;
        padding: 10px 0; } }

.button.arrow-right:after, .filter__order-by .arrow-right.radio:after,
.filter__filter-by .arrow-right.radio:after, .button.arrow-dropdown:after, .filter__order-by .arrow-dropdown.radio:after,
.filter__filter-by .arrow-dropdown.radio:after,
.btn.arrow-right:after,
.btn.arrow-dropdown:after,
button.arrow-right:after,
button.arrow-dropdown:after,
input.arrow-right[type=button]:after,
input.arrow-dropdown[type=button]:after,
input.arrow-right[type="submit"]:after,
input.arrow-dropdown[type="submit"]:after, .button.arrow-left:before, .filter__order-by .arrow-left.radio:before,
.filter__filter-by .arrow-left.radio:before,
.btn.arrow-left:before,
button.arrow-left:before,
input.arrow-left[type=button]:before,
input.arrow-left[type="submit"]:before {
  content: '';
  position: relative;
  right: -0.2em;
  top: -0.1em;
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 3px #3c4d59 solid;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  transition: transform 0.45s ease-in-out;
  flex-shrink: 0; }
  .large-text .button.arrow-right:after, .large-text .filter__order-by .arrow-right.radio:after, .filter__order-by .large-text .arrow-right.radio:after,
  .large-text .filter__filter-by .arrow-right.radio:after, .filter__filter-by .large-text .arrow-right.radio:after, .large-text .button.arrow-dropdown:after, .large-text .filter__order-by .arrow-dropdown.radio:after, .filter__order-by .large-text .arrow-dropdown.radio:after,
  .large-text .filter__filter-by .arrow-dropdown.radio:after, .filter__filter-by .large-text .arrow-dropdown.radio:after,
  .large-text .btn.arrow-right:after,
  .large-text .btn.arrow-dropdown:after,
  .large-text button.arrow-right:after,
  .large-text button.arrow-dropdown:after,
  .large-text input.arrow-right[type=button]:after,
  .large-text input.arrow-dropdown[type=button]:after,
  .large-text input.arrow-right[type="submit"]:after,
  .large-text input.arrow-dropdown[type="submit"]:after, .large-text .button.arrow-left:before, .large-text .filter__order-by .arrow-left.radio:before, .filter__order-by .large-text .arrow-left.radio:before,
  .large-text .filter__filter-by .arrow-left.radio:before, .filter__filter-by .large-text .arrow-left.radio:before,
  .large-text .btn.arrow-left:before,
  .large-text button.arrow-left:before,
  .large-text input.arrow-left[type=button]:before,
  .large-text input.arrow-left[type="submit"]:before {
    top: -0.2em; }

.button, .filter__order-by .radio,
.filter__filter-by .radio,
.btn,
button,
input[type=button],
input[type="submit"] {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  background-color: #fff;
  color: #3c4d59;
  border: 3px solid currentcolor;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  font-size: 1.2em;
  text-decoration: none;
  font-family: "Verlag Condensed";
  line-height: 1;
  text-align: left;
  cursor: pointer; }
  .large-text .button, .large-text .filter__order-by .radio, .filter__order-by .large-text .radio,
  .large-text .filter__filter-by .radio, .filter__filter-by .large-text .radio, .large-text
  .btn, .large-text
  button, .large-text
  input[type=button], .large-text
  input[type="submit"] {
    font-size: 1.1em; }
  .button.arrow-right, .filter__order-by .arrow-right.radio,
  .filter__filter-by .arrow-right.radio, .button.arrow-dropdown, .filter__order-by .arrow-dropdown.radio,
  .filter__filter-by .arrow-dropdown.radio,
  .btn.arrow-right,
  .btn.arrow-dropdown,
  button.arrow-right,
  button.arrow-dropdown,
  input[type=button].arrow-right,
  input[type=button].arrow-dropdown,
  input[type="submit"].arrow-right,
  input[type="submit"].arrow-dropdown {
    padding: 15px 30px 12px 20px;
    justify-content: center; }
    .button.arrow-right.btn-inverse:after, .filter__order-by .arrow-right.btn-inverse.radio:after,
    .filter__filter-by .arrow-right.btn-inverse.radio:after, .button.arrow-dropdown.btn-inverse:after, .filter__order-by .arrow-dropdown.btn-inverse.radio:after,
    .filter__filter-by .arrow-dropdown.btn-inverse.radio:after,
    .btn.arrow-right.btn-inverse:after,
    .btn.arrow-dropdown.btn-inverse:after,
    button.arrow-right.btn-inverse:after,
    button.arrow-dropdown.btn-inverse:after,
    input[type=button].arrow-right.btn-inverse:after,
    input[type=button].arrow-dropdown.btn-inverse:after,
    input[type="submit"].arrow-right.btn-inverse:after,
    input[type="submit"].arrow-dropdown.btn-inverse:after {
      border-color: #fff; }
  .button.arrow-left, .filter__order-by .arrow-left.radio,
  .filter__filter-by .arrow-left.radio,
  .btn.arrow-left,
  button.arrow-left,
  input[type=button].arrow-left,
  input[type="submit"].arrow-left {
    padding: 15px 20px 12px 30px;
    text-align: center; }
    .button.arrow-left:before, .filter__order-by .arrow-left.radio:before,
    .filter__filter-by .arrow-left.radio:before,
    .btn.arrow-left:before,
    button.arrow-left:before,
    input[type=button].arrow-left:before,
    input[type="submit"].arrow-left:before {
      right: auto;
      left: -0.2em;
      transform: rotate(-135deg); }
    .button.arrow-left.btn-inverse:before, .filter__order-by .arrow-left.btn-inverse.radio:before,
    .filter__filter-by .arrow-left.btn-inverse.radio:before,
    .btn.arrow-left.btn-inverse:before,
    button.arrow-left.btn-inverse:before,
    input[type=button].arrow-left.btn-inverse:before,
    input[type="submit"].arrow-left.btn-inverse:before {
      border-color: #fff; }
  .button.arrow-dropdown:after, .filter__order-by .arrow-dropdown.radio:after,
  .filter__filter-by .arrow-dropdown.radio:after,
  .btn.arrow-dropdown:after,
  button.arrow-dropdown:after,
  input[type=button].arrow-dropdown:after,
  input[type="submit"].arrow-dropdown:after {
    transform: rotate(135deg); }
  .button.arrow-dropdown[aria-expanded=true]:after, .filter__order-by .arrow-dropdown.radio[aria-expanded=true]:after,
  .filter__filter-by .arrow-dropdown.radio[aria-expanded=true]:after,
  .btn.arrow-dropdown[aria-expanded=true]:after,
  button.arrow-dropdown[aria-expanded=true]:after,
  input[type=button].arrow-dropdown[aria-expanded=true]:after,
  input[type="submit"].arrow-dropdown[aria-expanded=true]:after {
    transform: translateY(3px) rotate(-45deg); }
  .button.btn-inverse, .filter__order-by .btn-inverse.radio,
  .filter__filter-by .btn-inverse.radio,
  .btn.btn-inverse,
  button.btn-inverse,
  input[type=button].btn-inverse,
  input[type="submit"].btn-inverse {
    background-color: #3c4d59;
    border-color: #3c4d59;
    color: #fff; }
  .button.btn-green:after, .filter__order-by .btn-green.radio:after,
  .filter__filter-by .btn-green.radio:after, .button.area-3:after, .filter__order-by .area-3.radio:after,
  .filter__filter-by .area-3.radio:after, .button.area-2:after, .filter__order-by .area-2.radio:after,
  .filter__filter-by .area-2.radio:after, .button.area-1:after, .filter__order-by .area-1.radio:after,
  .filter__filter-by .area-1.radio:after,
  .btn.btn-green:after,
  .btn.area-3:after,
  .btn.area-2:after,
  .btn.area-1:after,
  button.btn-green:after,
  button.area-3:after,
  button.area-2:after,
  button.area-1:after,
  input[type=button].btn-green:after,
  input[type=button].area-3:after,
  input[type=button].area-2:after,
  input[type=button].area-1:after,
  input[type="submit"].btn-green:after,
  input[type="submit"].area-3:after,
  input[type="submit"].area-2:after,
  input[type="submit"].area-1:after {
    border-color: #fff; }
  .button.btn-green, .filter__order-by .btn-green.radio,
  .filter__filter-by .btn-green.radio, .button.area-3, .filter__order-by .area-3.radio,
  .filter__filter-by .area-3.radio,
  .btn.btn-green,
  .btn.area-3,
  button.btn-green,
  button.area-3,
  input[type=button].btn-green,
  input[type=button].area-3,
  input[type="submit"].btn-green,
  input[type="submit"].area-3 {
    background-color: #73c27b;
    border-color: #73c27b;
    color: #fff; }
    .area-3 .button.btn-green, .area-3 .filter__order-by .btn-green.radio, .filter__order-by .area-3 .btn-green.radio,
    .area-3 .filter__filter-by .btn-green.radio, .filter__filter-by .area-3 .btn-green.radio, .area-3 .button.area-3, .area-3 .filter__order-by .area-3.radio, .filter__order-by .area-3 .area-3.radio,
    .area-3 .filter__filter-by .area-3.radio, .filter__filter-by .area-3 .area-3.radio, .area-3
    .btn.btn-green, .area-3
    .btn.area-3, .area-3
    button.btn-green, .area-3
    button.area-3, .area-3
    input[type=button].btn-green, .area-3
    input[type=button].area-3, .area-3
    input[type="submit"].btn-green, .area-3
    input[type="submit"].area-3 {
      background-color: #3c4d59;
      border-color: #3c4d59; }
  .button.area-1, .filter__order-by .area-1.radio,
  .filter__filter-by .area-1.radio,
  .btn.area-1,
  button.area-1,
  input[type=button].area-1,
  input[type="submit"].area-1 {
    background-color: #5569aa;
    border-color: #5569aa;
    color: #fff; }
  .button.area-2, .filter__order-by .area-2.radio,
  .filter__filter-by .area-2.radio,
  .btn.area-2,
  button.area-2,
  input[type=button].area-2,
  input[type="submit"].area-2 {
    background-color: #c14952;
    border-color: #c14952;
    color: #fff; }
  .button.off, .filter__order-by .off.radio,
  .filter__filter-by .off.radio,
  .btn.off,
  button.off,
  input[type=button].off,
  input[type="submit"].off {
    opacity: 0;
    height: 1px; }

img {
  width: 100%;
  height: auto;
  vertical-align: top; }

figure {
  margin: 0; }

figcaption {
  display: block;
  vertical-align: top;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.875rem; }

.video__holder,
.audio__holder {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden; }
  .video__holder > div,
  .video__holder video audio,
  .audio__holder > div,
  .audio__holder video audio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.audio__holder {
  padding-top: 30px; }
  .audio__holder .video-js .vjs-control-bar {
    display: flex; }
  .audio__holder .video-js .vjs-big-play-button {
    display: none; }

.video__holder .video-js .vjs-big-play-button {
  height: 3em;
  margin-top: -1.5em;
  border-radius: 50%; }

.video__holder .video-js .vjs-icon-placeholder {
  line-height: 3em; }

.story-detail__comments .control-group {
  margin-top: 24px; }

.story-detail__comments #div_id_check_spam_field {
  display: none; }

.story-detail__comments input[type=text],
.story-detail__comments textarea {
  width: 100%;
  border: 2px #e2e2e2 solid;
  box-sizing: border-box;
  padding: 0.5em; }

.story-detail__comments label {
  display: block;
  font-size: 0.875em;
  padding-bottom: 6px;
  color: #595959; }
  .story-detail__comments label.hidden {
    display: none; }

.story-detail__comments input[type=submit] {
  margin-left: auto;
  margin-top: 12px; }
  .story-detail__comments input[type=submit] + p {
    margin-top: 0.5em; }

.message {
  background-color: #5569aa;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #fff; }
  .message.success {
    background-color: #73c27b; }
  .message p {
    padding: 1em;
    transition: opacity 0.45s ease-in-out; }
  .message.faded-out p {
    opacity: 0;
    transition-duration: 0s; }

form {
  position: relative; }

.filters {
  position: relative;
  z-index: 2; }
  @media (min-width: 720px) {
    .filters .filter__options {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; } }
  .filters input[type="submit"] {
    margin-top: 6px;
    min-width: 200px; }
  .filters .control-label {
    display: none; }
  .filters p {
    padding-bottom: 4px;
    line-height: 1.2; }
    @media (min-width: 720px) {
      .filters p {
        padding-bottom: 8px; } }

@media (max-width: 1023px) {
  .large-text .filters .filter__options {
    display: block; } }

@media (max-width: 900px) {
  .med-large-text .filters .filter__options {
    display: block; } }

.filter__order-by,
.filter__filter-by {
  padding-top: 16px;
  padding-bottom: 16px; }
  .filter__order-by .radio,
  .filter__filter-by .radio {
    cursor: pointer;
    position: relative; }
    .filter__order-by .radio.checked,
    .filter__filter-by .radio.checked {
      color: #fff;
      background-color: #3c4d59;
      border-color: #3c4d59; }
    .filter__order-by .radio.focus:after,
    .filter__filter-by .radio.focus:after {
      display: block; }
    .filter__order-by .radio:after,
    .filter__filter-by .radio:after {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      display: none; }
    .filter__order-by .radio input[type="radio"],
    .filter__filter-by .radio input[type="radio"] {
      position: absolute;
      left: -99999px; }

@media (min-width: 720px) {
  .filter__filter-by {
    margin-right: 24px; } }

.filter__filter-by .controls {
  display: flex;
  flex-wrap: wrap; }

.filter__filter-by .radio {
  margin-top: 6px; }
  .filter__filter-by .radio:not(:last-child) {
    margin-right: 6px; }
  .filter__filter-by .radio:nth-child(2) {
    color: #5569aa; }
    .filter__filter-by .radio:nth-child(2).checked {
      background-color: #5569aa;
      border-color: #5569aa; }
  .filter__filter-by .radio:nth-child(3) {
    color: #c14952; }
    .filter__filter-by .radio:nth-child(3).checked {
      background-color: #c14952;
      border-color: #c14952; }
  .filter__filter-by .radio:nth-child(4) {
    color: #73c27b; }
    .filter__filter-by .radio:nth-child(4).checked {
      background-color: #73c27b;
      border-color: #73c27b; }
  .filter__filter-by .radio.checked {
    color: #fff; }

.filter__order-by {
  position: relative; }
  .filter__order-by .dropdown-toggle {
    z-index: 2;
    min-width: 200px;
    margin-top: 6px; }
  .filter__order-by > .control-group {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3px;
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.3s ease-in-out; }
    .filter__order-by > .control-group.filter__slide-in {
      transform: translateY(100%);
      opacity: 1; }
  .filter__order-by .radio {
    width: 100%; }

.content-top-bar {
  color: #fff;
  border-top: 1px #e2e2e2 solid;
  background-color: #32404a; }
  .content-top-bar p {
    padding-bottom: 0; }
  .content-top-bar .inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  .content-top-bar.area-2 {
    background-color: #c14952;
    border-color: #c14952; }
  .content-top-bar.area-3 {
    background-color: #73c27b;
    border-color: #73c27b; }

.content-top__info {
  font-family: "Verlag Condensed";
  letter-spacing: 1px;
  font-size: 1.2em;
  padding-top: 0;
  text-transform: capitalize; }

.pagination {
  font-family: "Verlag Condensed";
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em; }
  @media (min-width: 721px) {
    .pagination .arrow-right {
      order: 3; } }

.pagination__numbers .span-current {
  color: #73c27b; }

.pagination__numbers span,
.pagination__numbers a {
  font-size: 1em;
  padding: 0.35em;
  text-decoration: none; }

@media (max-width: 720px) {
  .pagination__numbers {
    text-align: center;
    width: 100%;
    margin-right: 0;
    margin-top: 1em;
    border-top: 3px solid #e2e2e2;
    padding-top: 0.65em; } }

@media (max-width: 1023px) {
  .large-text .pagination__numbers {
    text-align: center;
    width: 100%;
    margin-right: 0;
    margin-top: 1em; }
  .large-text .pagination .arrow-right {
    order: 0; } }

@media (max-width: 520px) {
  .large-text .pagination span,
  .large-text .pagination a,
  .med-large-text .pagination span,
  .med-large-text .pagination a {
    width: 100%;
    text-align: center; }
  .large-text .pagination a + a,
  .med-large-text .pagination a + a {
    margin-top: 0.25em; } }

.expand-content {
  padding-top: 1em;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
  border-bottom: 3px solid #e2e2e2;
  line-height: 1.6; }
  .expand-content .exp-content-inner {
    padding-bottom: 1em; }

.expand-button:first-child {
  margin-top: 0.833em;
  margin-left: auto; }

.expand-button[aria-expanded="true"] + .expand-content {
  height: auto;
  max-height: 400px;
  overflow: auto; }

.story-detail__comments-holder:last-child {
  margin: 48px 0; }

.story-detail__comment {
  border: 2px #e2e2e2 solid;
  padding: 24px;
  padding-top: 26px; }
  .story-detail__comment:first-child, .story-detail__comment:last-child {
    margin: 16px 0 12px 0; }
  .story-detail__comment .comment-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    line-height: 1.35; }
  .story-detail__comment .comment-name {
    font-weight: bold;
    text-transform: capitalize; }
  .story-detail__comment .comment-date {
    color: #595959;
    font-size: 0.875em; }
  .story-detail__comment .separator:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #999;
    margin: 0 0.4em; }

.story-detail__comment-separator {
  border-top: 3px #d8e8f8 solid;
  margin: 16px 0 32px 0; }

.story-list__hero .button, .story-list__hero .filter__order-by .radio, .filter__order-by .story-list__hero .radio,
.story-list__hero .filter__filter-by .radio, .filter__filter-by .story-list__hero .radio {
  flex-shrink: 0;
  position: relative;
  font-size: 2em;
  margin-top: 0.5em; }

@media (min-width: 720px) {
  .story-list__hero .inner-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; } }

@media (min-width: 720px) and (max-width: 1023px) {
  .med-large-text .story-list__hero .inner-container,
  .large-text .story-list__hero .inner-container {
    display: block; }
  .med-large-text .story-list__hero .button, .med-large-text .story-list__hero .filter__order-by .radio, .filter__order-by .med-large-text .story-list__hero .radio,
  .med-large-text .story-list__hero .filter__filter-by .radio, .filter__filter-by .med-large-text .story-list__hero .radio,
  .large-text .story-list__hero .button,
  .large-text .story-list__hero .filter__order-by .radio,
  .filter__order-by .large-text .story-list__hero .radio,
  .large-text .story-list__hero .filter__filter-by .radio,
  .filter__filter-by .large-text .story-list__hero .radio {
    top: 0;
    margin-top: 0.5em; }
  .med-large-text .story-list__hero .story-list__hero-text,
  .large-text .story-list__hero .story-list__hero-text {
    width: 100%; } }

@media (min-width: 720px) {
  #innerbody:not(.large-text):not(.med-large-text) .story-list__hero-text {
    width: 66.66%;
    padding-right: 1em; } }

.story-list__access-link.button, .filter__order-by .story-list__access-link.radio,
.filter__filter-by .story-list__access-link.radio {
  top: -1.5em; }

.story-list__links {
  position: relative; }

.story-list__holder {
  padding-top: 16px;
  transition: opacity 0.45s ease-in-out; }
  @media (min-width: 720px) {
    .story-list__holder {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -6px;
      width: calc(100% + 12px); } }
  .story-list__holder.no-transition {
    transition-duration: 0s; }
  .story-list__holder.faded-out {
    opacity: 0; }

.story-list__card {
  position: relative;
  padding: 12px 0; }
  @media (min-width: 720px) {
    .story-list__card {
      width: 33.33%; }
      .story-list__card, .story-list__card:first-child, .story-list__card:last-child {
        padding: 12px 6px; } }
  @media (min-width: 720px) and (max-width: 1023px) {
    .med-large-text .story-list__card,
    .large-text .story-list__card {
      width: 50%; } }
  @media (max-width: 920px) {
    .large-text .story-list__card {
      width: 100%; } }
  @media (min-width: 1200px) {
    .story-detail__sidebar .story-list__card {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1050px) {
    #innerbody:not(.large-text) .story-detail__sidebar .story-list__card {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 920px) {
    #innerbody:not(.med-large-text):not(.large-text) .story-detail__sidebar .story-list__card {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }

.story-list__card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f4f4f4;
  position: relative; }
  .story-detail__sidebar .story-list__card-inner {
    background-color: #fff;
    border-bottom: 4px #d8e8f8 solid;
    padding-bottom: 20px;
    display: block; }

.story-list__cta {
  color: #fff;
  display: block;
  text-align: center;
  font-size: 1.3em;
  background-color: #5569aa;
  border-color: #5569aa;
  width: 100%; }
  .area-2 .story-list__cta {
    background-color: #c14952;
    border-color: #c14952; }
  .area-3 .story-list__cta {
    background-color: #73c27b;
    border-color: #73c27b; }
  .story-list__cta.button, .filter__order-by .story-list__cta.radio,
  .filter__filter-by .story-list__cta.radio {
    padding-right: 20px; }
    .story-list__cta.button:after, .filter__order-by .story-list__cta.radio:after,
    .filter__filter-by .story-list__cta.radio:after {
      border-color: #fff; }
  .story-detail__sidebar .story-list__cta {
    font-size: 1em;
    padding-top: 9px;
    padding-bottom: 8px;
    flex-grow: 1;
    margin-bottom: 12px;
    max-width: calc(100% - 80px); }
    .story-detail__sidebar .story-list__cta:after {
      display: none; }
  .large-text .story-detail__sidebar .story-list__cta {
    font-size: 0.9em; }

.story-list__image-holder {
  height: 206px;
  overflow: hidden;
  border-bottom: 6px #5569aa solid; }
  .story-detail__sidebar .story-list__image-holder {
    height: 106px;
    border-top: 6px #5569aa solid;
    border-bottom: none; }
  .area-2 .story-list__image-holder {
    border-color: #c14952; }
  .area-3 .story-list__image-holder {
    border-color: #73c27b; }

.story-list__image {
  height: 206px;
  background-color: #333;
  background-size: cover;
  background-position: center;
  transition: transform 0.6s ease-in-out; }

.story-list__text {
  padding: 16px;
  padding-bottom: 0; }
  .story-detail__sidebar .story-list__text {
    padding-left: 0;
    padding-right: 0; }
  .story-list__text h3:last-child {
    margin-bottom: 0.66em; }

.story-list__date {
  display: block;
  margin-top: auto;
  padding-left: 16px;
  padding-right: 16px; }
  .story-detail__sidebar .story-list__date {
    display: none; }

.story-list__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }
  .story-list__link:hover + .story-list__image-holder .story-list__image {
    transform: scale(1.05); }

.story-detail__top .inner-container {
  padding-top: 0.65em;
  padding-bottom: 0.75em; }
  .story-detail__top .inner-container > * {
    margin: 0.25em 1em 0.25em 0; }
    .story-detail__top .inner-container > *:last-child {
      margin-right: 0; }

.story-detail__top .button, .story-detail__top .filter__order-by .radio, .filter__order-by .story-detail__top .radio,
.story-detail__top .filter__filter-by .radio, .filter__filter-by .story-detail__top .radio {
  justify-content: center; }
  @media (max-width: 720px) {
    .story-detail__top .button, .story-detail__top .filter__order-by .radio, .filter__order-by .story-detail__top .radio,
    .story-detail__top .filter__filter-by .radio, .filter__filter-by .story-detail__top .radio {
      width: 100%; } }

.story-detail__top p {
  line-height: 1.35; }

.story-detail__section {
  margin-top: calc(16px + 1.5em); }
  .story-detail__section h2 {
    font-family: "Open Sans";
    color: #73c27b;
    font-size: 0.875em; }
  .story-detail__section h3 {
    margin-bottom: 0;
    color: #73c27b; }
  .story-detail__section p:last-child {
    padding-bottom: 0; }

.story-detail__sidebar h2,
.story-detail__sidebar h3 {
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: bold; }

@media (max-width: 919px) {
  .story-detail__sidebar {
    margin-top: 32px; } }

@media (max-width: 1049px) {
  .med-large-text .story-detail__sidebar {
    margin-top: 32px; } }

@media (max-width: 1199px) {
  .large-text .story-detail__sidebar {
    margin-top: 32px; } }

@media (min-width: 720px) and (max-width: 919px) {
  .story-detail__sidebar-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -6px;
    width: calc(100% + 12px);
    justify-content: flex-start; } }

@media (min-width: 720px) and (max-width: 1049px) {
  .med-large-text .story-detail__sidebar-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -6px;
    width: calc(100% + 12px);
    justify-content: flex-start; } }

@media (min-width: 720px) and (max-width: 1199px) {
  .large-text .story-detail__sidebar-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -6px;
    width: calc(100% + 12px);
    justify-content: flex-start; } }

.story-detail__sidebar-share {
  background-color: #d8e8f8;
  padding: 24px 16px;
  position: relative; }
  .story-detail__sidebar-share h3 {
    font-family: "Verlag Condensed";
    font-size: 1.8em;
    margin-bottom: 0;
    line-height: 1.2; }
  .story-detail__sidebar-share .button, .story-detail__sidebar-share .filter__order-by .radio, .filter__order-by .story-detail__sidebar-share .radio,
  .story-detail__sidebar-share .filter__filter-by .radio, .filter__filter-by .story-detail__sidebar-share .radio {
    justify-content: center; }
  .story-detail__sidebar-share .story-list__access-link {
    bottom: 24px;
    right: 16px;
    left: 16px;
    top: auto; }

.story-detail__resource p {
  padding-bottom: 12px;
  font-weight: bold;
  line-height: 1.35; }

@media (min-width: 720px) {
  #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource {
    display: flex;
    align-content: center; }
    #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource p {
      padding-bottom: 0; }
    #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource .button, #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource .filter__order-by .radio, .filter__order-by #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource .radio,
    #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource .filter__filter-by .radio, .filter__filter-by #innerbody:not(.med-large-text):not(.large-text) .story-detail__resource .radio {
      flex-shrink: 0;
      margin-left: 16px; } }

.story__social-icon,
.story__clap-icon {
  display: block;
  flex-shrink: 0; }
  .story__social-icon .icon,
  .story__clap-icon .icon {
    max-width: 30px;
    max-height: 30px;
    margin-top: 50%;
    transform: translateY(-50%);
    fill: #5569aa;
    transition: transform 0.45s ease-in-out; }

.story-list__clap {
  display: none; }

.story-detail__sidebar .icon {
  fill: #999;
  margin-right: 6px; }

.story-detail__sidebar .story-list__clap {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 34px;
  margin-bottom: 12px;
  max-width: 56px; }
  .story-detail__sidebar .story-list__clap span {
    position: relative;
    top: 2px;
    line-height: 30px;
    color: #595959; }

.story-detail__sidebar .story__clap-icon {
  height: 30px; }

.large-text .story-detail__sidebar .story-list__clap,
.med-large-text .story-detail__sidebar .story-list__clap {
  height: auto; }
  .large-text .story-detail__sidebar .story-list__clap span,
  .med-large-text .story-detail__sidebar .story-list__clap span {
    line-height: 1.2; }

.story-list__cta-clap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.story-detail__actions {
  margin-top: 48px;
  border-bottom: 3px solid #d8e8f8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (max-width: 720px) {
    .story-detail__actions {
      border-top: 3px solid #d8e8f8;
      padding-top: 48px; } }
  .story-detail__actions .story__social-icon,
  .story-detail__actions .story__clap-icon {
    display: block;
    width: 67px;
    height: 68px;
    border-radius: 50%;
    text-align: center;
    border: 2px #5569aa solid; }
  .story-detail__actions .story__social-icon {
    margin-left: 12px; }
    .story-detail__actions .story__social-icon + .social-icon {
      margin-left: 8px; }
    .story-detail__actions .story__social-icon.social-twitter {
      border-color: #1DA1F2; }
      .story-detail__actions .story__social-icon.social-twitter svg {
        fill: #1DA1F2; }
  .story-detail__actions .story__clap-icon {
    border-color: #73c27b; }
    .story-detail__actions .story__clap-icon svg {
      fill: #73c27b; }
  .story-detail__actions .clap-icon-holder {
    padding-right: 16px;
    position: relative; }
  .story-detail__actions a:hover .icon {
    transform: translateY(-50%) scale(1.2); }

.story-detail__clap,
.story-detail__share {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .story-detail__clap p,
  .story-detail__share p {
    display: block;
    padding: 0;
    flex-shrink: 1;
    font-weight: bold;
    line-height: 1.35; }

.story-detail__share {
  padding-bottom: 48px; }

.story-detail__clap {
  margin-right: 16px;
  margin-bottom: 48px; }
  .story-detail__clap .clap-count {
    font-weight: normal;
    text-align: center;
    position: absolute;
    bottom: -4px;
    transform: translateY(100%);
    font-size: 0.935em;
    width: 100%;
    text-align: left; }

.story-detail__next {
  border-bottom: 3px #d8e8f8 solid;
  padding-bottom: 48px; }
  @media (min-width: 720px) {
    .story-detail__next .inner {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .story-detail__next .story-list__text {
    padding: 0;
    padding-right: 1em; }
    .story-detail__next .story-list__text h3 {
      color: #3c4d59;
      font-family: "Open Sans";
      font-size: 1.2em;
      margin-bottom: 6px; }
  .story-detail__next .button, .story-detail__next .filter__order-by .radio, .filter__order-by .story-detail__next .radio,
  .story-detail__next .filter__filter-by .radio, .filter__filter-by .story-detail__next .radio {
    padding-top: 9px;
    padding-bottom: 7px; }

.anim-404 {
  max-width: 300px;
  width: 100%;
  flex-shrink: 0; }
  @media (max-width: 520px) {
    .anim-404 {
      display: none; } }

.page-404 {
  min-height: 400px; }
  @media (min-width: 720px) {
    .page-404 {
      display: flex;
      justify-content: flex-start; } }
  .page-404 h1 {
    text-transform: uppercase; }
  .page-404 .text-404 {
    padding-top: 2em; }

* {
  box-sizing: border-box; }

:first-child {
  margin-top: 0; }

:last-child {
  margin-bottom: 0; }
